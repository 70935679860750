<template>
	<div class="headquarters">
		<div class="banner">
			<img src="../assets/hdq/banner.jpg" class="banner_p" />
		</div>
		<div class="content">
			<img src="../assets/hdq/bg.jpg" class="bg" />
			<div class="box">
				<div class="box_content">
					<img src="../assets/hdq_icon.png" class="itr_icon" />
					<div class="main_title">
						上海马尼亚
					</div>
					<div class="main_title">
						娱乐咨询管理有限公司
					</div>
					<div class="main_title_en">
						MANIA TECHNOLOGY
					</div>
					<p class="int_sys">
						跑跑太空熊是上海马尼亚娱乐咨询管理有限公司2020年推出儿童共享童车品牌。 延承多年从事儿童游乐，玩具行业始终坚持对产品严谨认真的态度。加入企业文化传承，结合多年来运用管理体系。 致力于打造当下优质的物联网儿童游艺产品
					</p>
					<div class="flex_row">
						<div class="appeal">
							<img src="../assets/hdq/2.png" class="app_bg"/>
							<div class="appeal_box">
								<div class="appeal_title">
									旗下品牌1-跑跑太空熊
								</div>
								<div class="appeal_title_en">
									BRAND APPEAL
								</div>
								<p class="app_sys">
									1.跑跑太空熊 是多元化集团借由一个品牌搭建与投资者、消费者之间的桥梁。让全新的无人共享童车进入各个幸福美满家庭的生活中。 身为行业的领导者，我们清楚舞台对品牌的重要性。
								</p>
								<p class="app_sys">
									2.我们相信快乐的体验，安全卫生的产品要从行业的领导者发起，所以创造三赢的循环就是集团的目标。让新兴的品牌成长，消费者更有意愿体验的产品，帮助更加有实力的投资者扩张市场，彼此串联来相互提升。
								</p>
							</div>
						</div>
						<div class="appeal">
							<img src="../assets/hdq/1.png" class="app_bg"/>
							<div class="appeal_box">
								<div class="appeal_title">
									旗下品牌2-跑跑太空猫
								</div>
								<div class="appeal_title_en">
									BRAND APPEAL
								</div>
								<div class="builing">
									正在打造
								</div>
								<div class="more">后续将打造更多优质品牌</div>
								<div class="more">让我们拭目以待吧！</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'headquarters',
		
	}
</script>

<style scoped="scoped">
	.box{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.banner{
		width: 100%;
		height: 600px;
	}
	.banner_p{
		width: 100%;
		height: 100%;
	}
	.content{
		width: 100%;
		position: relative;
	}
	.bg{
		width: 100%;
	}
	.box_content{
		width: 1200px;
		margin: 0 auto;
		box-sizing: border-box;
		padding: 60px 10px;
	}
	.itr_icon{
		position: absolute;
		width: 100px;
		top: 60px;
		left: 490px;
	}
	.main_title{
		font-size: 52px;
		color: #fff95a;
		font-weight: bold;
	}
	.main_title_en{
		color: #d3e5ff;
		font-size: 30px;
		position: relative;
	}
	.main_title_en::after{
		content: '';
		display: block;
		width: 30px;
		height: 1px;
		background-color: #d3e5ff;
		margin: 0 auto;
	}
	.int_sys{
		width: 1000px;
		margin: 0 auto;
		margin-top: 20px;
		color: #fff;
		font-size: 26px;
	}
	.flex_row{
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		margin-top: 75px;
	}
	.appeal{
		width: 40%;
		position: relative;
	}
	.app_bg{
		width: 100%;
	}
	.appeal_box{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 60px 60px 70px 40px;
	}
	.appeal_title{
		text-align: left;
		font-size: 30px;
		color: #333;
	}
	.appeal_title_en{
		text-align: left;
		font-size: 24px;
		color: #999;
	}
	.appeal_title_en::after{
		content: '';
		display: block;
		width: 30px;
		height: 1px;
		background-color: #333;
		margin-top: 5px;
	}
	.app_sys{
		text-align: left;
		margin-top: 10px;
		color: #333;
		font-size: 20px;
	}
	.builing{
		width: 100%;
		height: 200px;
		line-height: 200px;
		font-size: 50px;
		color: #333;
		margin-top: 20px;
	}
	.more{
		font-size: 20px;
		color: #333;
	}
</style>
