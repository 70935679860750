<template>
	<div class="surrounding">
		<div class="nav">
			<img src="../assets/banner1.jpg" class="banner_p" />
		</div>
		<div class="content">
			<img src="../assets/co/bg.jpg" class="bg" />
			<div class="box">
				<div class="co_content">
					<img src="../assets/hdq_icon.png" class="co_icon"/>
					<div class="co_title">
						跑跑太空熊品牌周边产品
					</div>
					<div class="co_title_en">
						GO GO BEAR PERIPHERAL PRODUCTS
					</div>
					<div class="sur">
						<div class="sur_left">
							<div :class="index == 1?'ac':'nomal'" @click="change(1)">杯子</div>
							<div :class="index == 2?'ac':'nomal'" @click="change(2)">匙扣</div>
							<div :class="index == 3?'ac':'nomal'" @click="change(3)">T恤</div>
							<div :class="index == 4?'ac':'nomal'" @click="change(4)">本子</div>
							<div :class="index == 5?'ac':'nomal'" @click="change(5)">笔</div>
						</div>
						<div class="sur_right">
							<img src="../assets/co/1.png" class="white" />
							<div class="sur_box" v-if="index == 1">
								<div class="sur_row">
									<div>
										<img src="../assets/goods/cup1.jpg" class="pro_img" />
									</div>
									<div>
										<img src="../assets/goods/cup2.jpg" class="pro_img" />
									</div>
								</div>
								<div class="sur_row" style="margin-top: 5px;">
									<div>
										<img src="../assets/goods/cup3.jpg" class="pro_img" />
									</div>
									<div>
										<img src="../assets/goods/cup4.jpg" class="pro_img" />
									</div>
								</div>
							</div>
							<div class="sur_box" v-if="index == 2">
								<div class="sur_row">
									<div>
										<img src="../assets/goods/k1.png" class="pro_img" />
									</div>
									<div>
										<img src="../assets/goods/k2.png" class="pro_img" />
									</div>
								</div>
								<div class="sur_row" style="margin-top: 5px;">
									<div>
										<img src="../assets/goods/k3.png" class="pro_img" />
									</div>
									<div>
										<img src="../assets/goods/k4.png" class="pro_img" />
									</div>
								</div>
							</div>
							<div class="sur_box" v-if="index == 3">
								<div class="sur_row">
									<div>
										<img src="../assets/goods/cl1.jpg" class="pro_img" />
									</div>
									<div>
										<img src="../assets/goods/cl2.jpg" class="pro_img" />
									</div>
								</div>
								<div class="sur_row" style="margin-top: 5px;">
									<div>
										<img src="../assets/goods/cl3.jpg" class="pro_img" />
									</div>
									<div>
										<img src="../assets/goods/cl4.jpg" class="pro_img" />
									</div>
								</div>
							</div>
							<div class="sur_box" v-if="index == 4">
								<div class="sur_row">
									<div>
										<img src="../assets/goods/b1.jpg" class="pro_img" style="height: 88%;"/>
									</div>
									<div>
										<img src="../assets/goods/b2.jpg" class="pro_img" style="height: 88%;"/>
									</div>
								</div>
								<div class="sur_row" style="margin-top: 5px;">
									<div>
										<img src="../assets/goods/b3.jpg" class="pro_img" style="height: 88%;"/>
									</div>
									<div>
										<img src="../assets/goods/b4.jpg" class="pro_img" style="height: 95%;"/>
									</div>
								</div>
							</div>
							<div class="sur_box" v-if="index == 5">
								<div class="sur_row">
									<div>
										<img src="../assets/goods/p1.jpg" class="pro_img" style="height: 88%;"/>
									</div>
									<div>
										<img src="../assets/goods/p2.jpg" class="pro_img" style="height: 88%;"/>
									</div>
								</div>
								<div class="sur_row" style="margin-top: 5px;">
									<div>
										<img src="../assets/goods/p3.jpg" class="pro_img" style="height: 88%;"/>
									</div>
									<div>
										<img src="../assets/goods/p4.jpg" class="pro_img" style="height: 88%;"/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="scan">
						<img src="../assets/sur/s_icon.png" class="scan_icon" />
						<div class="scan_title">扫小程序二维码可下单哦</div>
						<div class="scan_title_en">SCAN SMALL PROGRAM QR CODE CAN BE ORDERED OH</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'c',
		data(){
			return {
				index: 1
			}
		},
		methods:{
			change(i){
				console.log(i)
				this.index = i
			}
		}
	}
</script>

<style scoped="scoped">
	.nav{
		width: 100%;
		height: 600px;
	}
	.banner_p{
		width: 100%;
		height: 100%;
	}
	.content{
		width: 100%;
		position: relative;
	}
	.bg{
		width: 100%;
	}
	.box{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.co_content{
		width: 1200px;
		margin: 0 auto;
		position: relative;
		box-sizing: border-box;
		padding: 40px;
	}
	.co_icon{
		width: 80px;
		position: absolute;
		top: 50px;
		left: 230px;
	}
	.co_title{
		font-size: 52px;
		font-weight: bold;
		color: #fff95a;
	}
	.co_title_en{
		font-size: 30px;
		color: #d3e5ff;
	}
	.co_title_en::after{
		content: '';
		display: block;
		width: 30px;
		height: 1px;
		background-color: #d3e5ff;
		margin: 0 auto;
		margin-top: 5px;
	}
	.sur{
		width: 100%;
		margin-top: 40px;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.sur_left{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		width: 10%;
		height: 100%;
	}
	.sur_right{
		width: 80%;
		position: relative;
	}
	.nomal{
		width: 100px;
		height: 100px;
		line-height: 100px;
		border-radius: 50%;
		background-color: #d7d7f7;
		color: #fff;
		font-size: 40px;
		margin-top: 10px;
	}
	.ac{
		width: 100px;
		height: 100px;
		line-height: 100px;
		border-radius: 50%;
		background-color: #c455ff;
		color: #fff;
		font-size: 40px;
		border: 4px solid #e5b3ff;
		margin-top: 10px;
	}
	.white{
		width: 100%;
	}
	.sur_box{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.sur_row{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 97%;
		height: 48%;
	}
	.sur_row>div{
		width: 48%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.pro_img{
		/* width: 90%; */
		height: 98%;
		border-radius: 20px;
	}
	.scan{
		margin-top: 60px;
	}
	.scan_title{
		font-size: 26px;
		color: #fff95a;
	}
	.scan_title_en{
		font-size: 24px;
		color: #d3e5ff;
	}
</style>
