<template>
	<div class="cooperation">
		<div class="banner" :id="2">
			<img src="../assets/sur/banner.png"  class="banner_p"/>
		</div>
		<div class="content">
			<img src="../assets/sur/bg.jpg" class="bg" />
			<div class="box">
				<div class="box_content">
					<img src="../assets/hdq_icon.png" class="itr_icon" />
					<div class="main_title">
						关于合作
					</div>
					<div class="main_title_en">
						ABOUT COOPERATION
					</div>
					<p class="int_sys">
						我们有多种合作方式，收益可观
					</p>
					<div class="flex_row">
						<div class="mode">
							<img src="../assets/sur/1.png" class="white" />
							<div class="mode_content">
								<img src="../assets/icon_title.png" class="icon_title" />
								<div class="mode_title" :id="1">
									合作方式
								</div>
								<div class="mode_title_en">
									COOPERATION MODE
								</div>
								<p class="mode_sys" style="margin-top: 5px;">
									我们有2种合作方式，分别为"购买"与"租借"。了解详情请拨打 400-829-6068
								</p>
								<p class="mode_sys">
									一年的加盟费合计约50000元(多点位数大力优惠)：设备分"租借"和"购买"2种模式自由选择，起订量为1个点位，每个点位缴纳加盟费约5000元+约3✖15888元内置记账器购置费(推荐一个点位三个设备)合伙人享有约80%的营业额分成。
								</p>
							</div>
						</div>
						<div class="earnings">
							<img src="../assets/sur/2.png" class="white" />
							<div class="earn_content">
								<div class="earn_title">收益预算表</div>
								<div class="ear_row1" style="margin-top: 30px;">
									<div class="table_item">每个点位</div>
									<div class="table_item">日均20单</div>
									<div class="table_item">日均25单</div>
									<div class="table_item">日均30单</div>
								</div>
								<div class="ear_row1">
									<div class="table_item">平均客单价</div>
									<div class="table_item CD6288">25</div>
									<div class="table_item CD6288">25</div>
									<div class="table_item CD6288">25</div>
								</div>
								<div class="ear_row1">
									<div class="table_item">评价享受额</div>
									<div class="table_item CD6288">18.3万</div>
									<div class="table_item CD6288">22.8万</div>
									<div class="table_item CD6288">27.4万</div>
								</div>
								<div class="ear_row1">
									<div class="table_item">一年分成利益</div>
									<div class="table_item CD6288">14.6万</div>
									<div class="table_item CD6288">18.3万</div>
									<div class="table_item CD6288">21.9万</div>
								</div>
								<div class="ear_row1">
									<div class="table_item">三年分成利益</div>
									<div class="table_item CD6288">43.8万</div>
									<div class="table_item CD6288">54.8万</div>
									<div class="table_item CD6288">65.7万</div>
								</div>
								<div class="ear_row1">
									<div class="table_item">一年回报率</div>
									<div class="table_item CD6288">2.9倍</div>
									<div class="table_item CD6288">3.7倍</div>
									<div class="table_item CD6288">4.4倍</div>
								</div>
								<div class="ear_row1">
									<div class="table_item">三年回报率</div>
									<div class="table_item CD6288">8.8倍</div>
									<div class="table_item CD6288">11倍</div>
									<div class="table_item CD6288">13.1倍</div>
								</div>
							</div>
						</div>
					</div>
					<div class="flex_row" style="align-items: flex-end;">
						<div class="bear">
							<img src="../assets/bear.png" class="bear_pic"/>
						</div>
						
						<div class="co">
							<img src="../assets/sur/3.png" class="white"/>
							<div class="co_content">
								<img src="../assets/icon_title.png" class="icon_title2" />
								<div class="co_title">
									加盟门店实拍
								</div>
								<div class="co_title_en">
									CO-BRANDS
								</div>
								<p class="co_sys">以下均为加盟点位实拍图片(图片仅作部分展示)</p>
								<div class="co_pics">
									<Swiper>
										<slide>
											<img src="../assets/hot1.png" class="p_img"/>
										</slide>
										<slide>
											<img src="../assets/hot2.png" class="p_img"/>
										</slide>
										<slide>
											<img src="../assets/hot3.png" class="p_img"/>
										</slide>
										<slide>
											<img src="../assets/hot4.png" class="p_img"/>
										</slide>
									</Swiper>
								</div>
							</div>
						</div>
					</div>
					<div class="co_row">
						<img src="../assets/sur/4.png" class="white" />
						<div class="co_r_content">
							<img src="../assets/icon_title.png" class="icon_title3" />
							<div class="co_title" :id="3">
								合作品牌与授权
							</div>
							<div class="co_title_en">
								CO-BRANDS
							</div>
							<p class="co_sys">以下合作品牌与授权仅作为部分展示</p>
						</div>
					</div>
					<div class="honor">
						<el-carousel :interval="4000" type="card" height="300px" style="overflow: hidden;">
						    <el-carousel-item >
						      <img src="../assets/honor1.png" class="honor_pic"/>
						    </el-carousel-item>
								<el-carousel-item >
								  <img src="../assets/honor2.png" class="honor_pic"/>
								</el-carousel-item>
								<el-carousel-item >
								  <img src="../assets/honor3.png" class="honor_pic"/>
								</el-carousel-item>
								<el-carousel-item >
								  <img src="../assets/honor4.png" class="honor_pic"/>
								</el-carousel-item>
								<el-carousel-item >
								  <img src="../assets/honor5.png" class="honor_pic"/>
								</el-carousel-item>
						  </el-carousel>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Swiper, Slide } from 'vue-swiper-component';
	export default{
		name:'cooperation',
		components: {
			Swiper,
		  Slide
		},
	}
</script>

<style scoped="scoped">
	.banner{
		width: 100%;
		height: 600px;
	}
	.banner_p{
		width: 100%;
		height: 100%;
	}
	.bg{
		width: 100%;
	}
	.box{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.content{
		width: 100%;
		position: relative;
	}
	.bg{
		width: 100%;
	}
	.box_content{
		width: 1200px;
		margin: 0 auto;
		box-sizing: border-box;
		padding: 60px 10px;
	}
	.itr_icon{
		position: absolute;
		width: 100px;
		top: 60px;
		left: 490px;
	}
	.main_title{
		font-size: 52px;
		color: #fff95a;
		font-weight: bold;
	}
	.main_title_en{
		color: #d3e5ff;
		font-size: 30px;
		position: relative;
	}
	.main_title_en::after{
		content: '';
		display: block;
		width: 30px;
		height: 1px;
		background-color: #d3e5ff;
		margin: 0 auto;
	}
	.int_sys{
		width: 430px;
		margin: 0 auto;
		margin-top: 20px;
		color: #fff;
		font-size: 26px;
	}
	.flex_row{
		display: flex;
		justify-content: space-evenly;
		align-items: flex-start;
		margin-top: 30px;
	}
	.mode{
		width: 40%;
		position: relative;
	}
	.earnings{
		width: 40%;
		position: relative;
	}
	.white{
		width: 100%;
	}
	.bear{
		width: 40%;
		display: flex;
		justify-content: flex-end;
	}
	.bear_pic{
		width: 70%;
	}
	.co{
		width: 40%;
		position: relative;
	}
	.co_row{
		width: 60%;
		margin: 0 auto;
		margin-top: 40px;
		position: relative;
	}
	.honor{
		width: 800px;
		margin: 0 auto;
	}
	.honor_pic{
		height: 100%;
	}
	.mode_content{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 30px 80px 30px 100px;
	}
	.icon_title{
		position: absolute;
		top: 30px;
		left: 100px;
		width: 60px;
	}
	.mode_title{
		font-size: 42px;
		font-weight: bold;
		color: #5454b0;
	}
	.mode_title_en{
		font-size: 24px;
		color: #999;
	}
	.mode_title_en::after{
		content: '';
		display: block;
		width: 30px;
		height: 1px;
		background-color: #333;
		margin: 0 auto;
		margin-top: 5px;
	}
	.mode_sys{
		text-align: left;
		font-size: 16px;
		color: #333;
	}
	.earn_content{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 0px 40px 40px 30px;
	}
	.earn_title{
		font-size: 24px;
		font-weight: bold;
		color: #cc6287;
		line-height: 47px;
	}
	.ear_row1{
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 30px;
	}
	.table_item{
		width: 25%;
		font-weight: bold;
		text-align: left;
	}
	.CD6288{
		color: #cd6288;
		font-weight: normal;
	}
	.co_content{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 40px 50px 40px 40px;
	}
	.co_title{
		font-size: 42px;
		font-weight: bold;
		color: #5454b0;
	}
	.co_title_en{
		font-size: 24px;
		color: #999;
	}
	.co_title_en::after{
		content: '';
		display: block;
		width: 30px;
		height: 1px;
		background-color: #333;
		margin: 0 auto;
		margin-top: 5px;
	}
	.co_sys{
		margin-top: 5px;
		font-size: 20px;
		color: #333;
	}
	.icon_title2{
		position: absolute;
		top: 50px;
		left: 40px;
		width: 60px;
	}
	.co_pics{
		width: 100%;
		border-radius: 20px;
	}
	.p_img{
		width: 100%;
		border-radius: 20px;
	}
	.co_r_content{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 80px;
	}
	.icon_title3{
		position: absolute;
		top: 85px;
		left: 130px;
		width: 60px;
	}
</style>
