<template>
	<div class="news">
		<div class="news_box">
			<div class="n_title">
				跑跑太空熊相关资讯
			</div>
			<div class="">
				<a class="flex" href="https://mp.weixin.qq.com/s?__biz=Mzg2NjUxMzA0MA==&mid=2247488931&idx=1&sn=8b33e3fb3145a8ff757120bc96c6a22c&chksm=ce48ec5cf93f654a928c2308334363b42768cb1ee283d8ee491eed2db60a04117afbe2c496cf&token=1827860433&lang=zh_CN#rd">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p2.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						这才叫强强联手！
					</div>
				</a>
				<a class="flex" href="https://mp.weixin.qq.com/s?__biz=Mzg2NjUxMzA0MA==&mid=2247490948&idx=1&sn=81c11b032d3833210e6a4d6d3f693037&chksm=ce48e47bf93f6d6d3d29d3a879863b862718b88ccb476f88c3f5613acd601f98378353331016&token=1827860433&lang=zh_CN#rd">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/hot2.png" class="n_pic"/>
					</div>
					<div class="n_font">
						怎么商场都开始打亲子牌了？
					</div>
				</a>
				<a class="flex" href="https://www.bilibili.com/read/cv9891224">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/hot3.png" class="n_pic"/>
					</div>
					<div class="n_font">
						小朋友：共享儿童汽车真香
					</div>
				</a>
				<a class="flex" href="https://zhuanlan.zhihu.com/p/351614026">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/hot4.png" class="n_pic"/>
					</div>
					<div class="n_font">
						几十块就能玩几千块的玩具，还不占地方
					</div>
				</a>
				<a class="flex" href="https://www.sohu.com/a/451114071_120977332">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/hot1.png" class="n_pic"/>
					</div>
					<div class="n_font">
						电动玩具车太大家里没地方放怎么办？ 
					</div>
				</a>
				<a class="flex" href="https://www.bilibili.com/read/cv9016672">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p1.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						跑跑太空熊共享儿童智能玩具车的新玩法
					</div>
				</a>
				<a class="flex" href="https://zhuanlan.zhihu.com/p/351614026">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p3.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						几十块就能玩几千块的玩具，还不占地方
					</div>
				</a>
				<a class="flex" href="https://www.bilibili.com/read/cv9959461">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p4.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						共享玩具可以让孩子学会爱惜玩具
					</div>
				</a>
				<a class="flex" href="https://www.bilibili.com/read/cv10007415">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p5.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						跑跑太空熊共享儿童汽车如何受到消费者青睐
					</div>
				</a>
				<a class="flex" href="https://www.bilibili.com/read/cv12227884">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p6.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						体验一下跑跑太空熊儿童共享汽车
					</div>
				</a>
				<a class="flex" href="https://www.bilibili.com/read/cv12275261">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p7.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						带儿子体验了一次商场共享童车
					</div>
				</a>
				<a class="flex" href="https://www.bilibili.com/read/cv12620525">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p8.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						智能共享儿童车，表弟超爱玩！
					</div>
				</a>
				<a class="flex" href="https://zhuanlan.zhihu.com/p/399020139">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p9.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						共享童车智能柜为什么能占领市场，小编来分析下
					</div>
				</a>
				<a class="flex" href="https://zhuanlan.zhihu.com/p/401067516">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p10.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						共享儿童车市场怎么样？
					</div>
				</a>
				<a class="flex" href="https://www.bilibili.com/read/cv12839163">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p11.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						“共享经济”新业态：儿童汽车也能共享了
					</div>
				</a>
				<a class="flex" href="https://www.bilibili.com/read/cv9210735">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p12.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						共享经济新发展，跑跑太空熊抢占市场高地，共享童车智能柜独占九大优势助力！
					</div>
				</a>
				<a class="flex" href="https://www.bilibili.com/read/cv9016672">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p13.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						跑跑太空熊共享儿童智能玩具车的新玩法
					</div>
				</a>
				<a class="flex" href="https://www.bilibili.com/read/cv8940695">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p14.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						跑跑太空熊儿童玩具汽车批发
					</div>
				</a>
				<a class="flex" href="https://zhuanlan.zhihu.com/p/339075429">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p15.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						共享儿童玩具汽车有哪些？
					</div>
				</a>
				<a class="flex" href="https://www.bilibili.com/read/cv9226130">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p16.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						跑跑太空熊共享童车模式的成功，源于可以解决家长问题
					</div>
				</a>
				<a class="flex" href="https://www.bilibili.com/read/cv9255649">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p17.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						从单车共享和共享充电宝，到现在的共享童车
					</div>
				</a>
				<a class="flex" href="https://www.bilibili.com/read/cv9324322">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p18.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						跑跑太空熊共享童车，孩子的快乐童年
					</div>
				</a>
				<a class="flex" href="https://www.bilibili.com/read/cv9457994">
					<div class="img">
						<div class="img_shadow"></div>
						<img src="../assets/news/p19.jpg" class="n_pic"/>
					</div>
					<div class="n_font">
						今天在小区看到的了一个方便你我他的共享童车
					</div>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'news',
		components: {
			
		},
		data(){
			return {
				
			}
		},
		methods:{
			
		}
	}
</script>

<style scoped="scoped">
	.news_box{
		width: 100%;
		box-sizing: border-box;
		padding: 20px;
		background-color: #fff;
	}
	.flex{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 60px 0;
	}
	.img{
		width: 50%;
		position: relative;
	}
	.n_pic{
		width: 100%;
	}
	.img_shadow{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
	.n_font{
		width: 50%;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
		text-decoration: underline;
		color: #000000;
		font-size: 24px;
	}
	a{
		color: #000000;
	}
	.n_title{
		font-weight: bold;
		font-size: 30px;
		color: #5454B0;
	}
</style>
